module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    USER_ADD_NEW: 'USER_ADD_NEW',
    PROFILE_UPDATE: 'PROFILE_UPDATE',
    CONFIRMATION: 'CONFIRMATION',
    DEFAULT: '',
    UPDATE_USER: 'UPDATE_USER',
    BULK_EDIT_USER: 'BULK_EDIT_USER'
  },

  RIGHT_DRAWER_TYPES: {
    NOTIFICATION: 'NOTIFICATION',
    CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  },

  CONFIRMATION_MODAL_CLOSE_TYPES: {
    USER_DELETE: 'USER_DELETE',
    PREZ_DELETE: 'PREZ_DELETE',
  },

  SORT: {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
  },

  USER_ROLE: {
    USER: 'user',
    MODERATOR: 'moderator',
    ADMIN: 'admin',
  },

  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  APP_HOST: process.env.REACT_APP_HOST,
  APP_NAME: process.env.REACT_APP_NAME,
  APP_LOGO: process.env.REACT_APP_LOGO,
});
