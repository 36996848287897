import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchCurrentUserSuccess: (state, action) => {
      state.currentUser = action.payload.currentUser;
    },
  },
});

export const { fetchCurrentUserSuccess } = authSlice.actions;
export default authSlice.reducer;
