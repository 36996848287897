import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../features/auth/authSlice';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  auth: authSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
