import { lazy } from 'react';

export const AppRoutes = {
  DASHBOARD: '/',
  PRESENTATIONS: '/presentations',
  TEMPLATES: '/templates',
  USERS: '/users',

  LOGIN: '/login',
  FORBIDDEN: '/forbidden',
};

export const PUBLIC_ROUTES = [AppRoutes.LOGIN, AppRoutes.FORBIDDEN];

const Dashboard = lazy(() => import('../pages/protected/Dashboard'));
const PresentationDetail = lazy(() =>
  import('../pages/protected/PresentationDetail')
);
const Presentations = lazy(() => import('../pages/protected/Presentations'));
const UserDetail = lazy(() => import('../pages/protected/UserDetail'));
const Users = lazy(() => import('../pages/protected/Users'));

const routes = [
  {
    path: AppRoutes.DASHBOARD,
    component: Dashboard,
  },
  {
    path: AppRoutes.USERS,
    component: Users,
  },
  {
    path: AppRoutes.USERS + '/:userId',
    component: UserDetail,
  },
  {
    path: AppRoutes.PRESENTATIONS,
    component: Presentations,
  },
  {
    path: AppRoutes.PRESENTATIONS + '/:presentationId',
    component: PresentationDetail,
  },
];

export default routes;
